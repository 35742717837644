/* 
########################################################################
# Imports
########################################################################
*/

import React, { useEffect, useState } from 'react';
import '../../styles/offering/OfferingArticles.css';
import { fetchArticlesByOffering } from '../../services/articleService'; 

/* 
########################################################################
# Core Logic
########################################################################
*/

const OfferingArticles = ({ offeringName }) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const loadArticles = async () => {
      if (offeringName) {
        const fetchedArticles = await fetchArticlesByOffering(offeringName);
        setArticles(fetchedArticles);
      }
    };

    loadArticles();
  }, [offeringName]);

  if (!articles.length) {
    return null;
  }

  return (
    <div className="articles-container">
      <div className="header-box">
        View my blog articles with {offeringName} tags
      </div>
      <div className="grid-container">
        {articles.map((article, index) => (
          <a key={index} className="article-box" href={article.article_url} target="_blank" rel="noopener noreferrer">
            {article.article_name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default OfferingArticles;

/* 
########################################################################
# END
########################################################################
*/