/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import useFetchOfferings from '../hooks/useFetchOfferings';
import OfferingSection from '../components/global/OfferingSections';

/* 
########################################################################
# Core Logic
########################################################################
*/

const OfferingContainer = () => {
    const { offerings, loading, error } = useFetchOfferings();

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (offerings.length === 0) return null;

    return <OfferingSection offerings={offerings} />;
}

export default OfferingContainer;

/* 
########################################################################
# END
########################################################################
*/