// Function to get the base URL with port if present
export const getBaseURLWithPort = (url) => {
    try {
        const parsedUrl = new URL(url);
        // Keep the original protocol
        const protocol = parsedUrl.protocol;
        return parsedUrl.port ? `${protocol}//${parsedUrl.hostname}:${parsedUrl.port}` : `${protocol}//${parsedUrl.hostname}`;
    } catch (error) {
        console.error('Invalid URL:', error);
        return url; // Return the original URL if parsing fails
    }
};

// Function to get the current version number
export const getCurrentVersionNumber = () => {
    return 'v1';
};
