/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import '../../styles/home/ContactSection.css';

/* 
########################################################################
# Core Logic
########################################################################
*/

const ContactSection = ({
    name, setName, organizationName, setOrganizationName, email, setEmail, projectBudget, setProjectBudget,
    phone, setPhone, projectDetails, setProjectDetails, handleSubmit, error, loading
}) => {
    return (
        <div id="contact-section">
            <div className="contact-title">Contact Us</div>
            <form className="contact-form" onSubmit={handleSubmit}>
                {error && <div className="error-popup">{error}</div>}
                <input
                    type="text"
                    placeholder="Your Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    disabled={loading} // Disable input when loading
                />
                <input
                    type="text"
                    placeholder="Organization Name"
                    value={organizationName}
                    onChange={e => setOrganizationName(e.target.value)}
                    disabled={loading} // Disable input when loading
                />
                <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    disabled={loading} // Disable input when loading
                />
                <input
                    type="tel"
                    placeholder="Your Phone"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    disabled={loading} // Disable input when loading
                />
                <input
                    type="number"
                    placeholder="Project Budget"
                    value={projectBudget}
                    onChange={e => setProjectBudget(e.target.value)}
                    disabled={loading} // Disable input when loading
                />
                <textarea
                    placeholder="Project Details"
                    value={projectDetails}
                    onChange={e => setProjectDetails(e.target.value)}
                    disabled={loading} // Disable input when loading
                />
                <div className="bottom-panel">
                    <div className="submit-button-container">
                        <button type="submit" disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ContactSection;

/* 
########################################################################
# END
########################################################################
*/