/* 
########################################################################
# Imports
########################################################################
*/

import { useState, useEffect, useMemo } from 'react';
import * as clientService from '../services/clientService';

/* 
########################################################################
# Core Logic
########################################################################
*/

function useFetchClients(apiFuncName, apiFuncArgs = []) {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchFunction = useMemo(() => clientService[apiFuncName], [apiFuncName]);

    useEffect(() => {
        let isMounted = true; // To prevent setting state on unmounted component
        setLoading(true);
        if (!fetchFunction) {
            setError(new Error('API function does not exist'));
            setLoading(false);
            return;
        }

        const fetchData = async () => {
            try {
                const data = await fetchFunction(...apiFuncArgs);
                if (isMounted) {
                    setClients(data);
                    setError(null);
                    setLoading(false);
                }
            } catch (err) {
                if (isMounted) {
                    setError(err);
                    setClients([]);
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [fetchFunction, apiFuncName, ...apiFuncArgs]); // Spread apiFuncArgs here

    return { clients, loading, error };
}

export default useFetchClients;

/* 
########################################################################
# END
########################################################################
*/
