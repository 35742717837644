// FourGrid.js
import React from 'react';
import PropTypes from 'prop-types';

const FourGrid = ({ items, renderItem }) => (
    <div className="four-grid">
        {items.length > 0 ? (
            items.map(renderItem)
        ) : (
            <p className="four-grid-empty">No items available</p>
        )}
    </div>
);

FourGrid.propTypes = {
    items: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
};

export default FourGrid;