import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/global/Header.css';

function Header() {
    return (
        <header className="header">
            <div className="logo-and-text">
                <Link to="/" className="brand-name">
                    programITcash
                </Link>
            </div>   
        </header>
    );
}

export default Header;