/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/offering/OfferingHeader.css';

/* 
########################################################################
# Core Logic
########################################################################
*/

const OfferingHeader = ({ offering }) => {
    const navigate = useNavigate();

    return (
        <div className="offering-component-spacing">
            <div className="offering-top-panel">
                <div className="white-box"></div>
                <div className="default-box"></div>
                <img src={offering.offering_image_url} alt={offering.offering_name} className="offering-picture"/>
                <div className="content-box">
                    <h1 className="offering-info">{offering.offering_name}</h1>
                </div>
                <img 
                    src="/assets/icons/misc/back_button.png" 
                    alt="Go Back" 
                    className="back-button" 
                    onClick={() => navigate(-1)}
                    style={{ cursor: 'pointer' }}
                />
            </div>
        </div>
    );
}

export default OfferingHeader;

/* 
########################################################################
# END
########################################################################
*/