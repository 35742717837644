/* 
########################################################################
# Imports
########################################################################
*/

import { useState, useEffect } from 'react';
import { fetchOfferings } from '../services/offeringService';

/* 
########################################################################
# Core Logic
########################################################################
*/

function useFetchOfferings() {
    const [offerings, setOfferings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetchOfferings()
            .then(data => {
                setOfferings(data);
                setError(null);
            })
            .catch(err => {
                setError(err);
                setOfferings([]);
            })
            .finally(() => setLoading(false));
    }, []);

    return { offerings, loading, error };
}

export default useFetchOfferings;

/* 
########################################################################
# END
########################################################################
*/
