/* 
########################################################################
# Imports and constants
########################################################################
*/

import axios from 'axios';
import { getBaseURLWithPort, getCurrentVersionNumber } from '../utils/helpers';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const baseWithPort = getBaseURLWithPort(baseUrl);
const versionNumber = getCurrentVersionNumber();

/* 
########################################################################
# Get Services
########################################################################
*/

export const sendProposalData = async (proposalData) => {
    try {
        const response = await axios.post(`${baseWithPort}/${versionNumber}/create-proposal/`, proposalData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            throw new Error(error.response.data.message || "Proposal data did not submit.");
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error("No response received.");
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error("Error setting up request.");
        }
    }
};

/* 
########################################################################
# END
########################################################################
*/