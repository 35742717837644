// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page-image-section {
    display: flex;
    justify-content: center; /* Align items at the center of the container */
    align-items: stretch; /* Stretch items to fill the container height */
    height: 425px; /* Fixed height for the container */
}

.vertical-white-bar {
    flex: 0 0 25px; /* Fixed width, does not grow or shrink */
    background-color: #FFFFFF;
}

.home-page-image-section img {
    flex: 1 1 50%; /* Flex grow, flex shrink, and basis set to 50% */
    width: 100%; /* Ensure the width fills the flex basis */
    height: 100%; /* Ensure the height stretches to container height */
}

@media (max-width: 768px) {
    .home-page-image-section {
        flex-direction: column; /* Stack the images vertically */
        height: auto; /* Adjust height to accommodate stacked images */
    }

    .vertical-white-bar {
        display: none; /* Hide the vertical bar on smaller screens */
    }

    .home-page-image-section img {
        width: 100%; /* Full width for smaller screens */
        height: auto; /* Height becomes automatic to maintain aspect ratio */
        margin-bottom: 10px; /* Adds spacing between stacked images */
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/home/HomePageImageSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB,EAAE,+CAA+C;IACxE,oBAAoB,EAAE,+CAA+C;IACrE,aAAa,EAAE,mCAAmC;AACtD;;AAEA;IACI,cAAc,EAAE,yCAAyC;IACzD,yBAAyB;AAC7B;;AAEA;IACI,aAAa,EAAE,iDAAiD;IAChE,WAAW,EAAE,0CAA0C;IACvD,YAAY,EAAE,oDAAoD;AACtE;;AAEA;IACI;QACI,sBAAsB,EAAE,gCAAgC;QACxD,YAAY,EAAE,gDAAgD;IAClE;;IAEA;QACI,aAAa,EAAE,6CAA6C;IAChE;;IAEA;QACI,WAAW,EAAE,mCAAmC;QAChD,YAAY,EAAE,sDAAsD;QACpE,mBAAmB,EAAE,wCAAwC;IACjE;AACJ","sourcesContent":[".home-page-image-section {\n    display: flex;\n    justify-content: center; /* Align items at the center of the container */\n    align-items: stretch; /* Stretch items to fill the container height */\n    height: 425px; /* Fixed height for the container */\n}\n\n.vertical-white-bar {\n    flex: 0 0 25px; /* Fixed width, does not grow or shrink */\n    background-color: #FFFFFF;\n}\n\n.home-page-image-section img {\n    flex: 1 1 50%; /* Flex grow, flex shrink, and basis set to 50% */\n    width: 100%; /* Ensure the width fills the flex basis */\n    height: 100%; /* Ensure the height stretches to container height */\n}\n\n@media (max-width: 768px) {\n    .home-page-image-section {\n        flex-direction: column; /* Stack the images vertically */\n        height: auto; /* Adjust height to accommodate stacked images */\n    }\n\n    .vertical-white-bar {\n        display: none; /* Hide the vertical bar on smaller screens */\n    }\n\n    .home-page-image-section img {\n        width: 100%; /* Full width for smaller screens */\n        height: auto; /* Height becomes automatic to maintain aspect ratio */\n        margin-bottom: 10px; /* Adds spacing between stacked images */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
