/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import Header from '../components/global/Header';
import HomePageImageSection from '../components/home/HomePageImageSection';
import OfferingContainer from '../containers/OfferingContainer';
import AboutFounderSection from '../components/home/AboutFounderSection';
import IndustryContainer from '../containers/IndustryContainer';
import ClientContainer from '../containers/ClientContainer';
import ContactContainer from '../containers/ContactContainer';
import Footer from '../components/global/Footer';

/* 
########################################################################
# Core Logic
########################################################################
*/

function HomePage() {
    return (
        <div>
            <Header />
            <HomePageImageSection />
            <div className="general-white-box"></div>
            <OfferingContainer />
            <AboutFounderSection />
            <IndustryContainer />
            <ClientContainer fetchFunctionName="fetchClients" />
            <div className="general-white-box"></div>
            <div className="general-black-box"></div>
            <ContactContainer />
            <Footer />
        </div>
    )
}

export default HomePage;

/* 
########################################################################
# END
########################################################################
*/