/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import FourGrid from './FourGrid';

import { Link } from 'react-router-dom';
import '../../styles/home/OfferingSection.css';

/* 
########################################################################
# Core Logic
########################################################################
*/

const OfferingSection = ({ offerings }) => {
    const feImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

    return (
        <div className="offeringSection">
            <div className="offeringSection__blackBox"></div>
            <div className="offeringSection__whiteBox"></div>
            <div className="offeringSection__title">What we offer</div>
            <FourGrid items={offerings} renderItem={offering => (
                <Link to={offering.offering_url} key={offering.id} className="offeringSection__prettyCardLink">
                    <div className="offeringSection__prettyCard">
                        <img src={`${feImageUrl}${offering.offering_image_url}`} alt={offering.offering_name} />
                        <p>{offering.offering_name}</p>
                    </div>
                </Link>
            )} />
        </div>
    );
}

export default OfferingSection;

/* 
########################################################################
# END
########################################################################
*/