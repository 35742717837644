/* 
########################################################################
# Imports
########################################################################
*/

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOfferingsByURLPath } from '../services/offeringService';

import Header from '../components/global/Header';
import OfferingHeader from '../components/offering/OfferingHeader';
import DynamicOfferingDescription from '../components/offering/DynamicOfferingDescription'
import OfferingArticles from '../components/offering/OfferingArticles'
import ClientContainer from '../containers/ClientContainer';
import SkillContainer from '../containers/SkillContainer';
import Footer from '../components/global/Footer';

/* 
########################################################################
# Core Logic
########################################################################
*/

function OfferingPage() {
    const { offeringURL } = useParams();
    const [offeringData, setOfferingData] = useState({ offering_name: '', offering_image_url: '' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const offeringData = await fetchOfferingsByURLPath(offeringURL);
                if (offeringData.length > 0) {
                    setOfferingData({
                        offering_name: offeringData[0].offering_name,
                        offering_image_url: "/assets" + offeringData[0].offering_image_url
                    });
                }
            } catch (error) {
                console.error('Failed to fetch offering data:', error);
            }
        };

        fetchData();
    }, [offeringURL]);

    return (
        <div>
            <Header />
            <OfferingHeader offering={offeringData} />
            <DynamicOfferingDescription offeringName={offeringData.offering_name} />
            <OfferingArticles offeringName={offeringData.offering_name} />
            <div className="general-white-box"></div>
            {offeringData.offering_name && (
                <ClientContainer fetchFunctionName="fetchClientsByOffering" offeringName={offeringData.offering_name} />
            )}
            <div className="general-yellow-box"></div>
            <div className="general-white-box"></div>
            {offeringData.offering_name && (
                <SkillContainer fetchFunctionName="fetchSkillsByOffering" offeringName={offeringData.offering_name} />
            )}
            <div className="general-white-box"></div>
            <div className="general-black-box"></div>
            <Footer />
        </div>
    )
}

export default OfferingPage;

/* 
########################################################################
# END
########################################################################
*/