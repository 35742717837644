/* 
########################################################################
# Imports
########################################################################
*/

import React, { useState, useEffect } from 'react';
import ContactSection from '../components/global/ContactSection';
import { sendProposalData } from '../services/proposalService';

/* 
########################################################################
# Core Logic
########################################################################
*/

const ContactContainer = () => {
    const [name, setName] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [projectBudget, setProjectBudget] = useState('');
    const [projectDetails, setProjectDetails] = useState('');
    const [error, setError] = useState('');
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            setRecaptchaLoaded(true);
        };

        script.onerror = () => {
            console.error('Failed to load reCAPTCHA script');
        };
    }, []);

    const validateForm = () => {
        if (name.length < 2 || name.length > 100) {
            setError('Name must be between 2 and 100 characters.');
            return false;
        }
        
        if (organizationName.length < 1) {
            setError('Organization name must be populated.');
            return false;
        }

        if (email.length < 1) {
            setError('Email must be populated.');
            return false;
        }

        if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone)) {
            setError('Phone number is not valid.');
            return false;
        }

        if (projectBudget < 3500) {
            setError('Project budget must be greater than $3500.');
            return false;
        }
        
        if (projectDetails.length <= 32) {
            setError('Project details must be more than 32 characters.');
            return false;
        }
        return true;
    };

    const handleResponse = async (token = '') => {
        try {
            const proposalData = { name, organization_name: organizationName, email, phone, project_budget: projectBudget, project_details: projectDetails, captcha_token: token };
            const response = await sendProposalData(proposalData);
            console.log('Submission successful', response);

            // Clear form values after 1.5 seconds
            setTimeout(() => {
                setName('');
                setOrganizationName('');
                setEmail('');
                setPhone('');
                setProjectBudget('');
                setProjectDetails('');
                setError('');
                setLoading(false); // Reset loading state after clearing form
            }, 1500);
        } catch (err) {
            setLoading(false); // Reset loading state on error
            if (err.response && err.response.data) {
                const errors = err.response.data;
                const errorMessage = Object.values(errors).flat().join(', ');
                setError(errorMessage);
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setLoading(true); // Set loading state

        if (!isLocalhost) {
            if (recaptchaLoaded && window.grecaptcha) {
                const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' });
                await handleResponse(token);
            } else {
                setError('reCAPTCHA not loaded');
                setLoading(false); // Reset loading state if reCAPTCHA not loaded
            }
        } else {
            await handleResponse();
        }
    };

    return (
        <ContactSection
            name={name} setName={setName}
            organizationName={organizationName} setOrganizationName={setOrganizationName}
            email={email} setEmail={setEmail}
            projectBudget={projectBudget} setProjectBudget={setProjectBudget}
            phone={phone} setPhone={setPhone}
            projectDetails={projectDetails} setProjectDetails={setProjectDetails}
            handleSubmit={handleSubmit} error={error} loading={loading}
        />
    );
}

export default ContactContainer;

/* 
########################################################################
# END
########################################################################
*/
