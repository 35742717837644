/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import SkillSection from '../components/global/SkillSection';
import useFetchSkills from '../hooks/useFetchSkills';

/* 
########################################################################
# Core Logic
########################################################################
*/

const SkillContainer = ({ fetchFunctionName, offeringName = null }) => {
    const args = offeringName ? [offeringName] : [];
    const { skills, loading, error } = useFetchSkills(fetchFunctionName, args);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (skills.length === 0) return null;

    return <SkillSection skills={skills} />;
}

export default SkillContainer;

/* 
########################################################################
# END
########################################################################
*/
