/* 
########################################################################
# Imports and constants
########################################################################
*/

import axios from 'axios';

import {
    mockOfferingData
} from '../utils/mockData';

import { getBaseURLWithPort, getCurrentVersionNumber } from '../utils/helpers';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const useMockData = process.env.REACT_APP_USE_MOCK_DATA === 'true';
const baseWithPort = getBaseURLWithPort(baseUrl);
const versionNumber = getCurrentVersionNumber();

/* 
########################################################################
# Get Services
########################################################################
*/

/**
 * Fetch offerings.
 */

export const fetchOfferings = async () => {
    if (useMockData) {
        return Promise.resolve(mockOfferingData);
    } else {
        try {
            const response = await axios.get(`${baseWithPort}/${versionNumber}/offerings/`);
            return response.data;
        } catch (error) {
            console.error("There was a problem with the fetch operation for offerings: ", error);
            return []; // Return an empty array in case of error
        }
    }
};

/**
 * Fetch offerings by a specific URL path.
 * 
 * @param {string} path The URL path to fetch offerings from.
 * @returns {Promise<Array>} A promise that resolves to an array of offerings.
 */
export const fetchOfferingsByURLPath = async (path) => {
    if (useMockData) {
        // Filter the mock data based on the 'offering_url' that matches the provided path
        return Promise.resolve(mockOfferingData.filter(offering => offering.offering_url === `/offering/${path}`));
    } else {
        try {
            const response = await axios.get(`${baseWithPort}/${versionNumber}/offering-by-url/${path}`);
            return response.data;
        } catch (error) {
            console.error(`There was a problem fetching offerings from the path /offering/${path}: `, error);
            return []; // Return an empty array in case of error
        }
    }
};

/**
 * Fetch offering content by offering name.
 * 
 * @param {string} offeringName The name of the offering to fetch content for.
 * @returns {Promise<Array>} A promise that resolves to an array of offering content.
 */
export const fetchOfferingContentByOffering = async (offeringName) => {
    if (useMockData) {
        return Promise.resolve(mockOfferingData);
    } else {
        try {
            const response = await axios.post(`${baseWithPort}/${versionNumber}/offering-content/`, {
                offering_name: offeringName
            });
            console.log(response)
            return response.data;
        } catch (error) {
            console.error(`There was a problem fetching content for the offering: ${offeringName}`, error);
            return []; // Return an empty array in case of error
        }
    }
};

/* 
########################################################################
# END
########################################################################
*/