/* 
########################################################################
# Default Mock Data
########################################################################
*/

const mockOfferingData = [
    {
        "offering_name": "Enterprise Applications (Web & Mobile)",
        "offering_image_url": `/icons/enterprise_applications.png`,
        "offering_url": "/offering/enterprise-applications"
    },
    {
        "offering_name": "General Websites",
        "offering_image_url": `/icons/general_websites.png`,
        "offering_url": "/offering/general-websites"
    },
    {
        "offering_name": "IT Strategy",
        "offering_image_url": `/icons/it_strategy.png`,
        "offering_url": "/offering/it-strategy"
    },
    {
        "offering_name": "Data Driven Solutions",
        "offering_image_url": `/icons/data_driven_solutions.png`,
        "offering_url": "/offering/data-driven-solutions"
    },
    {
        "offering_name": "Business Strategy",
        "offering_image_url": `/icons/business_strategy.png`,
        "offering_url": "/offering/business-strategy"
    },
    {
        "offering_name": "UX/UI Design",
        "offering_image_url": `/icons/ux_ui_design.png`,
        "offering_url": "/offering/ux-ui-design"
    },
    {
        "offering_name": "Blockchain Solutions",
        "offering_image_url": `/icons/blockchain_solutions.png`,
        "offering_url": "/offering/blockchain-solutions"
    },
    {
        "offering_name": "Artificial Intelligence",
        "offering_image_url": `/icons/artificial_intelligence.png`,
        "offering_url": "/offering/artificial-intelligence"
    },
    {
        "offering_name": "General App Development",
        "offering_image_url": `/icons/general_app_development.png`,
        "offering_url": "/offering/general-app-development"
    },
];

const mockIndustriesData = [
    {
        "industry_name": "Education",
        "industry_image_url": `/icons/education.png`
    },
    {
        "industry_name": "Energy & Utilities",
        "industry_image_url": `/icons/energy.png`
    },
    {
        "industry_name": "Financial Services",
        "industry_image_url": `/icons/financial.png`
    },
    {
        "industry_name": "Food & Beverage",
        "industry_image_url": `/icons/food.png`
    },
    {
        "industry_name": "Government & Public Sector",
        "industry_image_url": `/icons/government.png`
    },
    {
        "industry_name": "Healthcare",
        "industry_image_url": `/icons/healthcare.png`
    },
    {
        "industry_name": "Hospitality",
        "industry_image_url": `/icons/hospitality.png`
    },
    {
        "industry_name": "Legal Services",
        "industry_image_url": `/icons/legal.png`
    },
    {
        "industry_name": "Logistics",
        "industry_image_url": `/icons/logistics.png`
    },
    {
        "industry_name": "Manufacturing",
        "industry_image_url": `/icons/manufacturing.png`
    },
    {
        "industry_name": "Media & Entertainment",
        "industry_image_url": `/icons/media.png`
    },
    {
        "industry_name": "Real Estate",
        "industry_image_url": `/icons/real_estate.png`
    },
    {
        "industry_name": "Retail",
        "industry_image_url": `/icons/retail.png`
    },
    {
        "industry_name": "Sports & Recreation",
        "industry_image_url": `/icons/sports.png`
    },
    {
        "industry_name": "Technology",
        "industry_image_url": `/icons/technology.png`
    },
];

const mockClientsData = [
    {
        "client_name": "Smack",
        "client_image_url": `/images/smack_logo.png`,
        "client_description": "Smack: Emerging Technology App"
    },
];

const mockArticlesData = [
    {
        "article_id": 1,
        "article_name": "Implementing Domain-Driven Design & Achieving Zero Data Loss In A Distributed System: Case Studies and Solutions in Django and RabbitMQ",
        "article_url": "https://medium.com/@bryan.cash/implementing-domain-driven-design-achieving-zero-data-loss-in-a-distributed-system-case-studies-45361fa69a88"
    },
];

const mockArticlesByOfferingData = [
    {
        "article_id": 1,
        "article_name": "Implementing Domain-Driven Design & Achieving Zero Data Loss In A Distributed System: Case Studies and Solutions in Django and RabbitMQ",
        "article_url": "https://medium.com/@bryan.cash/implementing-domain-driven-design-achieving-zero-data-loss-in-a-distributed-system-case-studies-45361fa69a88",
        "offering_name": "Enterprise Applications (Web & Mobile)"
    },
    {
        "article_id": 2,
        "article_name": "Test 2: Implementing Domain-Driven Design & Achieving Zero Data Loss In A Distributed System: Case Studies and Solutions in Django and RabbitMQ",
        "article_url": "https://medium.com/@bryan.cash/implementing-domain-driven-design-achieving-zero-data-loss-in-a-distributed-system-case-studies-45361fa69a88",
        "offering_name": "Enterprise Applications (Web & Mobile)"
    },
    {
        "article_id": 3,
        "article_name": "Test 3: Implementing Domain-Driven Design & Achieving Zero Data Loss In A Distributed System: Case Studies and Solutions in Django and RabbitMQ",
        "article_url": "https://medium.com/@bryan.cash/implementing-domain-driven-design-achieving-zero-data-loss-in-a-distributed-system-case-studies-45361fa69a88",
        "offering_name": "Enterprise Applications (Web & Mobile)"
    },
    {
        "article_id": 4,
        "article_name": "Test 4: Implementing Domain-Driven Design & Achieving Zero Data Loss In A Distributed System: Case Studies and Solutions in Django and RabbitMQ",
        "article_url": "https://medium.com/@bryan.cash/implementing-domain-driven-design-achieving-zero-data-loss-in-a-distributed-system-case-studies-45361fa69a88",
        "offering_name": "Enterprise Applications (Web & Mobile)"
    },
];

const mockSkillsData = [
    {
        "skill_id": 1,
        "skill_name": "Python",
        "skill_image_url": "/icons/skills/python_logo.png"
    },
    {
        "skill_id": 2,
        "skill_name": "Go",
        "skill_image_url": "/icons/skills/go_logo.png"
    },
    {
        "skill_id": 3,
        "skill_name": "Rust",
        "skill_image_url": "/icons/skills/rust_logo.png"
    },
    {
        "skill_id": 4,
        "skill_name": "Kafka",
        "skill_image_url": "/icons/skills/kafka_logo.png"
    },
    {
        "skill_id": 5,
        "skill_name": "RabbitMQ",
        "skill_image_url": "/icons/skills/rabbit_mq_logo.png"
    },
    {
        "skill_id": 6,
        "skill_name": "Figma",
        "skill_image_url": "/icons/skills/figma_logo.png"
    },
    {
        "skill_id": 7,
        "skill_name": "React & React Native",
        "skill_image_url": "/icons/skills/react_logo.png"
    },
    {
        "skill_id": 8,
        "skill_name": "React",
        "skill_image_url": "/icons/skills/react_logo.png"
    },
    {
        "skill_id": 9,
        "skill_name": "React Native",
        "skill_image_url": "/icons/skills/react_logo.png"
    },
    {
        "skill_id": 10,
        "skill_name": "Solidity",
        "skill_image_url": "/icons/skills/solidity_logo.png"
    },
    {
        "skill_id": 11,
        "skill_name": "Ethereum",
        "skill_image_url": "/icons/skills/ethereum_logo.png"
    },
    {
        "skill_id": 12,
        "skill_name": "Kubernetes",
        "skill_image_url": "/icons/skills/kubernetes_logo.png"
    },
    {
        "skill_id": 13,
        "skill_name": "Modal Cloud",
        "skill_image_url": "/icons/skills/kubernetes_logo.png"
    },
    {
        "skill_id": 14,
        "skill_name": "Snowflake",
        "skill_image_url": "/icons/skills/snowflake_logo.png"
    },
    {
        "skill_id": 15,
        "skill_name": "SQL & NoSQL",
        "skill_image_url": "/icons/skills/sql_logo.png"
    },
    {
        "skill_id": 16,
        "skill_name": "SQL",
        "skill_image_url": "/icons/skills/sql_logo.png"
    },
    {
        "skill_id": 17,
        "skill_name": "NoSQL",
        "skill_image_url": "/icons/skills/sql_logo.png"
    },
    {
        "skill_id": 18,
        "skill_name": "Airflow",
        "skill_image_url": "/icons/skills/airflow_logo.png"
    },
    {
        "skill_id": 19,
        "skill_name": "Prefect",
        "skill_image_url": "/icons/skills/prefect_logo.png"
    },
    {
        "skill_id": 20,
        "skill_name": "Cloud Services",
        "skill_image_url": "/icons/skills/cloud_services_logo.png"
    },
    {
        "skill_id": 21,
        "skill_name": "Amazon Web Services (AWS)",
        "skill_image_url": "/icons/skills/cloud_services_logo.png"
    },
    {
        "skill_id": 22,
        "skill_name": "Microsoft Azure",
        "skill_image_url": "/icons/skills/cloud_services_logo.png"
    },
    {
        "skill_id": 23,
        "skill_name": "Google Cloud Platform (GCP)",
        "skill_image_url": "/icons/skills/cloud_services_logo.png"
    }
];

/* 
########################################################################
# Exports
########################################################################
*/

export {
    mockOfferingData,
    mockIndustriesData,
    mockClientsData,
    mockArticlesData,
    mockArticlesByOfferingData,
    mockSkillsData,
};

/* 
########################################################################
# END
########################################################################
*/