// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background-color: #545252;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures elements are spaced out */
    padding: 0 20px;
}

.logo-and-text {
    display: flex;
    align-items: center;
}

.brand-name {
    color: #FFFFFF;
    font-weight: 300;
    font-size: 20px;
    margin-left: 10px;
    text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .header {
        padding: 0 10px; /* Reduces padding on smaller screens */
    }

    .brand-name {
        font-size: 18px; /* Adjusts font size for smaller screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/global/Header.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B,EAAE,oCAAoC;IACpE,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA,2BAA2B;AAC3B;IACI;QACI,eAAe,EAAE,uCAAuC;IAC5D;;IAEA;QACI,eAAe,EAAE,0CAA0C;IAC/D;AACJ","sourcesContent":[".header {\n    background-color: #545252;\n    height: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between; /* Ensures elements are spaced out */\n    padding: 0 20px;\n}\n\n.logo-and-text {\n    display: flex;\n    align-items: center;\n}\n\n.brand-name {\n    color: #FFFFFF;\n    font-weight: 300;\n    font-size: 20px;\n    margin-left: 10px;\n    text-decoration: none;\n}\n\n/* Responsive adjustments */\n@media (max-width: 768px) {\n    .header {\n        padding: 0 10px; /* Reduces padding on smaller screens */\n    }\n\n    .brand-name {\n        font-size: 18px; /* Adjusts font size for smaller screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
