/* 
########################################################################
# Imports
########################################################################
*/

import { useState, useEffect, useMemo } from 'react';
import * as skillService from '../services/skillService';

/* 
########################################################################
# Core Logic
########################################################################
*/

function useFetchSkills(apiFuncName, apiFuncArgs = []) {
    const [skills, setSkills] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchFunction = useMemo(() => skillService[apiFuncName], [apiFuncName]);

    useEffect(() => {
        let isMounted = true; // To prevent setting state on unmounted component
        setLoading(true);
        if (!fetchFunction) {
            setError(new Error('API function does not exist'));
            setLoading(false);
            return;
        }

        const fetchData = async () => {
            try {
                const data = await fetchFunction(...apiFuncArgs);
                if (isMounted) {
                    setSkills(data);
                    setError(null);
                    setLoading(false);
                }
            } catch (err) {
                if (isMounted) {
                    setError(err);
                    setSkills([]);
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [fetchFunction, apiFuncName, ...apiFuncArgs]); // Spread apiFuncArgs here

    return { skills, loading, error };
}

export default useFetchSkills;

/* 
########################################################################
# END
########################################################################
*/
