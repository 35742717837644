/* 
########################################################################
# Imports and constants
########################################################################
*/

import axios from 'axios';

import {
    mockClientsData
} from '../utils/mockData';

import { getBaseURLWithPort, getCurrentVersionNumber } from '../utils/helpers';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const useMockData = process.env.REACT_APP_USE_MOCK_DATA === 'true';
const baseWithPort = getBaseURLWithPort(baseUrl);
const versionNumber = getCurrentVersionNumber();

/* 
########################################################################
# Get Services
########################################################################
*/

export const fetchClients = async () => {
    if (useMockData) {
        return Promise.resolve(mockClientsData);
    } else {
        try {
            const response = await axios.get(`${baseWithPort}/${versionNumber}/clients/`);
            return response.data;
        } catch (error) {
            console.error("There was a problem with the fetch operation for clients: ", error);
            return []; // Return an empty array in case of error
        }
    }
};

export const fetchHighlightedClients = async () => {
    if (useMockData) {
        return Promise.resolve(mockClientsData);
    } else {
        try {
            const response = await axios.get(`${baseWithPort}/${versionNumber}/highlighted-clients/`);
            return response.data;
        } catch (error) {
            console.error("There was a problem with the fetch operation for highlighted clients: ", error);
            return []; // Return an empty array in case of error
        }
    }
};

export const fetchClientsByOffering = async (offering_name) => {
    if (useMockData) {
        return Promise.resolve(mockClientsData);
    } else {
        try {
            const response = await axios.get(`${baseWithPort}/${versionNumber}/client-offerings/${offering_name}`);
            return response.data;
        } catch (error) {
            console.error("There was a problem with the fetch operation for clients by offering: ", error);
            return []; // Return an empty array in case of error
        }
    }
};

/* 
########################################################################
# END
########################################################################
*/
