/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import IndustrySection from '../components/global/IndustrySection';
import useFetchIndustries from '../hooks/useFetchIndustries';

/* 
########################################################################
# Core Logic
########################################################################
*/

const IndustryContainer = () => {
    const { industries, loading, error } = useFetchIndustries();

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (industries.length === 0) return null;

    return <IndustrySection industries={industries} />;
}

export default IndustryContainer;

/* 
########################################################################
# END
########################################################################
*/