// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */
.footer {
    width: 100%;
    background-color: #34F8E5;
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: 16px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer .links a {
    color: #545252;
    text-decoration: none;
    padding: 0 5px;
}

.footer .links a:hover,
.footer .links a:focus {
    text-decoration: underline;
}

/* Responsive adjustments for the footer */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column; /* Stacks the content vertically */
        text-align: center; /* Centers the content text */
        gap: 10px; /* Adds a little space between items when stacked */
    }

    .footer .links a {
        padding: 5px 0; /* Increases padding for better touch targets */
    }

    .footer {
        padding: 20px 10px; /* Adjusts padding to give more space vertically */
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/global/Footer.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,cAAc;AAClB;;AAEA;;IAEI,0BAA0B;AAC9B;;AAEA,0CAA0C;AAC1C;IACI;QACI,sBAAsB,EAAE,kCAAkC;QAC1D,kBAAkB,EAAE,6BAA6B;QACjD,SAAS,EAAE,mDAAmD;IAClE;;IAEA;QACI,cAAc,EAAE,+CAA+C;IACnE;;IAEA;QACI,kBAAkB,EAAE,kDAAkD;IAC1E;AACJ","sourcesContent":["/* Footer.css */\n.footer {\n    width: 100%;\n    background-color: #34F8E5;\n    padding: 10px 20px;\n    box-sizing: border-box;\n    font-size: 16px;\n}\n\n.footer-content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.footer .links a {\n    color: #545252;\n    text-decoration: none;\n    padding: 0 5px;\n}\n\n.footer .links a:hover,\n.footer .links a:focus {\n    text-decoration: underline;\n}\n\n/* Responsive adjustments for the footer */\n@media (max-width: 768px) {\n    .footer-content {\n        flex-direction: column; /* Stacks the content vertically */\n        text-align: center; /* Centers the content text */\n        gap: 10px; /* Adds a little space between items when stacked */\n    }\n\n    .footer .links a {\n        padding: 5px 0; /* Increases padding for better touch targets */\n    }\n\n    .footer {\n        padding: 20px 10px; /* Adjusts padding to give more space vertically */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
