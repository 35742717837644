/* 
########################################################################
# Imports and constants
########################################################################
*/

import axios from 'axios';

import {
    mockArticlesData,
    mockArticlesByOfferingData
} from '../utils/mockData';

import { getCurrentVersionNumber, getBaseURLWithPort} from '../utils/helpers';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const useMockData = process.env.REACT_APP_USE_MOCK_DATA === 'true';
const baseWithPort = getBaseURLWithPort(baseUrl);
const versionNumber = getCurrentVersionNumber();

/* 
########################################################################
# Get Services
########################################################################
*/

export const fetchArticles = async () => {
    if (useMockData) {
        return Promise.resolve(mockArticlesData);
    } else {
        try {
            const response = await axios.get(`${baseWithPort}/${versionNumber}/articles/`);
            return response.data;
        } catch (error) {
            console.error("There was a problem with the fetch operation for articles: ", error);
            return []; // Return an empty array in case of error
        }
    }
};

export const fetchArticlesByOffering = async (offeringName) => {
    if (useMockData) {
        return Promise.resolve(mockArticlesByOfferingData);
    } else {
        try {
            const response = await axios.get(`${baseWithPort}/${versionNumber}/article-offerings/${offeringName}`);
            return response.data;
        } catch (error) {
            console.error("There was a problem with the fetch operation for articles: ", error);
            return []; // Return an empty array in case of error
        }
    }
};

/* 
########################################################################
# END
########################################################################
*/
