/* 
########################################################################
# Imports
########################################################################
*/

import { useState, useEffect } from 'react';
import { fetchIndustries } from '../services/industryService';

/* 
########################################################################
# Core Logic
########################################################################
*/

function useFetchIndustries() {
    const [industries, setIndustries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetchIndustries()
            .then(data => {
                setIndustries(data);
                setError(null);
            })
            .catch(err => {
                setError(err);
                setIndustries([]);
            })
            .finally(() => setLoading(false));
    }, []);

    return { industries, loading, error };
}

export default useFetchIndustries;

/* 
########################################################################
# END
########################################################################
*/
