/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import ClientCaseStudiesSection from '../components/global/ClientCaseStudySection';
import useFetchClients from '../hooks/useFetchClients';

/* 
########################################################################
# Core Logic
########################################################################
*/

const ClientContainer = ({ fetchFunctionName, offeringName = null }) => {
    const args = offeringName ? [offeringName] : [];
    const { clients, loading, error } = useFetchClients(fetchFunctionName, args);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (clients.length === 0) return null;  // Return null if no clients are available

    return <ClientCaseStudiesSection clients={clients} />;
}

export default ClientContainer;

/* 
########################################################################
# END
########################################################################
*/
