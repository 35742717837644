/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import '../../styles/home/ClientCaseStudiesSection.css';
/* 
########################################################################
# Core Logic
########################################################################
*/

const ClientCaseStudiesSection = ({ clients }) => {
    const feImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

    return (
        <div className="client-case-studies-section">
            <div className="client-title">Clients/Case Studies</div>
            <div className="box-backgrounds">
                <div className="black-box"></div>
                <div className="default-background-box"></div>
            </div> 
            <div className="clients-grid">
                {clients.map((client, index) => (
                    <div key={index} className="client-circle-container">
                        <a href={`http://${client.client_website_url}`} target="_blank" rel="noopener noreferrer">
                            <div className="client-circle">
                                <img src={`${feImageUrl}${client.client_image_url}`} alt={client.client_description} />
                            </div>
                        </a>
                        <p className="client-description">{client.client_description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ClientCaseStudiesSection;

/* 
########################################################################
# END
########################################################################
*/