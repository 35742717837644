/* 
########################################################################
# Imports
########################################################################
*/

import React, { useEffect, useState } from 'react';
import { fetchOfferingContentByOffering } from '../../services/offeringService';
import '../../styles/offering/DynamicOfferingDescription.css';

/* 
########################################################################
# Core Logic
########################################################################
*/

const ContentBlock = ({ contentGroup, isOdd, isFirstBlock }) => {
  const blockClass = isOdd ? 'odd-block' : 'even-block';
  const additionalClass = isFirstBlock ? 'first-block' : ''; // Conditional class for the first block

  return (
    <div className={`content-block ${blockClass} ${additionalClass}`}>
      {isFirstBlock && (
        <div className="content-header">{contentGroup[0].content_header}</div>
      )}
      {contentGroup.map((entry, index) => (
        <React.Fragment key={index}>
          {entry.offering_content_type === "Content" && (
            <p className="content-text">{entry.offering_content}</p>
          )}
          {entry.offering_content_type === "Metric-Value" && (
            <div className="metric-circle">
              <span className="metric-value-text">{entry.offering_content}</span>
            </div>
          )}
          {entry.offering_content_type === "Metric-Content" && (
            <p className="metric-content">{entry.offering_content}</p>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const DynamicOfferingDescription = ({ offeringName }) => {
  const [contentGroups, setContentGroups] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contentData = await fetchOfferingContentByOffering(offeringName);

        const groupedContent = contentData.reduce((groups, item) => {
          const group = groups[item.ranking] || [];
          group.push(item);
          groups[item.ranking] = group;
          return groups;
        }, {});

        const contentGroupsArray = Object.values(groupedContent);
        setContentGroups(contentGroupsArray);
      } catch (err) {
        setError('Failed to fetch data');
        console.error(err);
      }
    };

    if (offeringName) {  // Ensure offeringName is present before fetching data
      fetchData();
    }
  }, [offeringName]);  // Dependency array now listens to offeringName

  if (error) {
    return <div className="error">Error loading content!</div>;
  }

  return (
    <div className="offering-component-spacing">
      <div className="offering-content-groups">
        {contentGroups.map((group, groupIndex) => (
          <ContentBlock
            key={groupIndex}
            contentGroup={group}
            isOdd={groupIndex % 2 === 0}
            isFirstBlock={groupIndex === 0}
          />
        ))}
      </div>
    </div>
  );
};

export default DynamicOfferingDescription;

/* 
########################################################################
# END
########################################################################
*/
