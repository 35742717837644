/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import '../../styles/home/IndustrySection.css';

/* 
########################################################################
# Core Logic
########################################################################
*/

const IndustrySection = ({ industries }) => {
    const feImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

    return (
        <div className="industry-section">
            <div className="industry-title">Industries</div>
            <div className="four-grid">
                {industries.map((industry, index) => (
                    <div className="pretty-card" key={index}>
                        <img src={`${feImageUrl}${industry.industry_image_url}`} alt={industry.industry_name} />
                        <p>{industry.industry_name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default IndustrySection;

/* 
########################################################################
# END
########################################################################
*/