/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import '../../styles/offering/SkillSection.css';

/* 
########################################################################
# Core Logic
########################################################################
*/

const SkillSection = ({ skills }) => {
    const feImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
    const numBlocks = Math.ceil(skills.length / 4);  // Determine the number of blocks

    return (
        <div className="skill-section">
            <div className="skill-header">Technical Skills</div>
            {Array.from({ length: numBlocks }).map((_, blockIndex) => (
                <React.Fragment key={blockIndex}>
                    <div className={`skill-block ${blockIndex === 0 ? 'first-block' : 'subsequent-block'}`}>
                        {skills.slice(blockIndex * 4, (blockIndex + 1) * 4).map((skill, index) => (
                            <div key={index} className="skill-container">
                                <div className="skill-circle">
                                    <img src={`${feImageUrl}${skill.skill_image_url}`} alt={skill.skill_name} />
                                </div>
                                {/* Apply different class based on block index */}
                                <p className={`${blockIndex === 0 ? 'skill-name' : 'subsequent-skill-name'}`}>
                                    {skill.skill_name}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="separator-bar"></div>
                </React.Fragment>
            ))}
        </div>
    );
}

export default SkillSection;

/* 
########################################################################
# END
########################################################################
*/