// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* IndustrySection.css */

.industry-section {
    background-color: #FFFFFF; /* White background for the entire section */
    padding: 20px;
    box-sizing: border-box;
}

.industry-title {
    text-align: left;
    margin-bottom: 20px;
    font-size: 24px;
    color: #545252;
}`, "",{"version":3,"sources":["webpack://./src/styles/home/IndustrySection.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;IACI,yBAAyB,EAAE,4CAA4C;IACvE,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,cAAc;AAClB","sourcesContent":["/* IndustrySection.css */\n\n.industry-section {\n    background-color: #FFFFFF; /* White background for the entire section */\n    padding: 20px;\n    box-sizing: border-box;\n}\n\n.industry-title {\n    text-align: left;\n    margin-bottom: 20px;\n    font-size: 24px;\n    color: #545252;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
