/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import '../../styles/home/HomePageImageSection.css';

/* 
########################################################################
# Core Logic
########################################################################
*/

function HomePageImageSection() {
    const virtualRealityPic = `${process.env.REACT_APP_IMAGE_BASE_URL}/images/virtual_reality_pic.webp`;
    const fairyLightsPic = `${process.env.REACT_APP_IMAGE_BASE_URL}/images/fairy_lights_pic.jpeg`;

    return (
        <div className="home-page-image-section">
            <img src={virtualRealityPic} alt="Virtual Reality"/>
            <div className="vertical-white-bar"></div>
            <img src={fairyLightsPic} alt="Fairy Lights"/>
        </div>
    )
}

export default HomePageImageSection;

/* 
########################################################################
# END
########################################################################
*/