/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import '../../styles/home/AboutFounderSection.css';

/* 
########################################################################
# Core Logic
########################################################################
*/

function AboutFounderSection() {
    const founderPic = `${process.env.REACT_APP_IMAGE_BASE_URL}/images/bryan_cash_founder.png`;

    return (
        <div className="about-founder-section">
            {/* First Section */}
            <div className="yellow-beauty-box founder-content">
                <div className="founder-pic-wrapper">
                    <img src={founderPic} alt="Founder: Bryan Cash" className="founder-pic" />
                    <p className="founder-name">Founder: Bryan Cash</p>
                </div>

                <div className="founder-info">
                    <h2 className="about-header">About Our Founder</h2>
                    <p className="about-writeup">
                    Bryan Cash is a visionary in the realm of technological advancement, with a career in pioneering cutting-edge applications and data-centric solutions. His leadership in crafting scalable infrastructures has been pivotal in streamlining operations for significant legal settlements, including orchestrating over $10 billion in settlements for the Volkswagen Emissions Scandal and facilitating more than $750 million for the Endo Transvaginal Mesh Settlement through bespoke, cutting-edge solutions. Beyond the legal sphere, Bryan’s introduction of advanced technology has propelled the Political Risk & Credit business, which is a subsidiary business for a larger specialty insurance company, to a leading position in a $3.6 billion industry. This helped accelerate the startup specialty insurance company he worked for to achieve over $1 billion in written premiums.
                    </p>
                </div>
            </div>

            {/* Separator */}
            <div className="styling-white-separator"></div>

            {/* Second Section */}
            <div className="yellow-beauty-box additional-content">
                <p className="additional-writeup">
                His technological strategies have also revitalized operations for esteemed organizations like the Washington Post, where his innovations led to millions in savings through optimization of printing operations, carrier management, and daily processes. Bryan’s expertise extends to crucial government projects with agencies such as the U.S. Citizen and Immigration Services (USCIS), the United States Agency for the International Development (USAID), and Health & Human Services (HHS), all being positively impacted by his work. Recently, as an entrepreneur, Bryan launched a cutting-edge sports betting platform, harnessing his expertise in data engineering and technology to revolutionize the industry.

                Beyond his professional achievements, Bryan is deeply committed to uplifting under-resourced communities. He offers free tutoring to minorities aspiring to enter the tech industry and visits students in inner-city schools with the intent of inspiring them with somebody who looks like them. This dedication fulfills his life’s purpose of redefining stereotypes. He is also adamant about helping small businesses reach their goals.

                Bryan Cash’s unique blend of technical acumen, visionary leadership, and altruistic commitment positions him as an invaluable asset to any endeavor.
                </p>
            </div>
        </div>
    );
}

export default AboutFounderSection;

/* 
########################################################################
# END
########################################################################
*/