/* 
########################################################################
# Imports
########################################################################
*/

// General Imports
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Pages
import HomePage from './pages/HomePage';
import OfferingPage from './pages/OfferingPage';

// Styles
import './styles/global/global.css';

/* 
########################################################################
# Core Logic
########################################################################
*/

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/offering/:offeringURL" element={<OfferingPage />} />
            </Routes>
        </Router>
    );
}

export default App;


/* 
########################################################################
# END
########################################################################
*/
